import React, {useEffect, useState} from 'react';
import PassportForm from '../../passport/FormData/PassportForm';
import { ContingentApi, DocumentApi } from '../../_helpers/service';
import { CONTINGENT_STATUS } from '../../_helpers/Constants';
import { PhotoWrapper } from './ContingentPassportStyle'
import Notice from '../../utils/Notice';
import { handleError } from '../../utils/handleError';
import { checkTypePhoto } from '../../utils/checkTypePhoto';

const getInfo = (data, t, lang) => {
  return [
    { label: t('contingentPassport_infoDirectionNumber'), value: data.directionId },
    { label: t('contingentPassport_infoDirectionStatus'), value: data.statusDirection[`${lang}_name`] },
    { label: t('contingentPassport_infoApplicantIIN'), value: data.iin },
  ].filter(item => item.value);
};
  
export default function ContingentInfo({ info, t, lang }) {
  const [urlImg, setUrlImg] = useState('');
  useEffect(() => {
    let value = PassportForm.values['form_contingent_do_child.Foto'];
    if (value) {
      DocumentApi.view(value['downloadLink'], value.version)
        .then(response => {
          setUrlImg(URL.createObjectURL(response.data));
        });
    }
  }, [PassportForm.values['form_contingent_do_child.Foto']]);

  useEffect(() => {
    setTimeout(() => {
      const elemet = document.getElementById('attach_form_contingent_do_child.Foto');
      const typeImg = 'image/jpeg, image/jpg, image/png, image/jfif'
      let check = false;
      elemet.type = 'text'
      elemet && elemet.addEventListener("click", async (event) => {
        try {
          if (info.id) {
            const data = await ContingentApi.canChangeChildPhoto(info.id)
            if (data.status === 200) {
              if (data.data.result.canChange) {
                elemet.type = 'file';
                if (elemet.type === 'file' && !check) {
                  check = true;
                  elemet.click()
                }
              } else {
                elemet.type = 'text'
                Notice.error(t('contingentPassport_photoUpdatedMonth'))
              }
            }
          } else {
            elemet.type = 'file'
            if (elemet.type === 'file' && !check) {
              check = true;
              elemet.click()
            }
          }
        } catch (error) {
          handleError(error, t('contingentPassport_errorVerifyPhotos'));
        }
      })
      elemet && elemet.addEventListener("change", (event) => {
        if (checkTypePhoto(event.target.files[0])) {
          event.target.value = ''
          Notice.error(t('contingentPassport_photoTypeFormatNot'))
        } else {
          setUrlImg(URL.createObjectURL(event.target.files[0]));
        }
      })
    }, 2000);
  }, [])

  return (
    <>
      {
        info.contingentStatus !== CONTINGENT_STATUS.ENROLLED &&
        <div className="mb2 card p2">
          {getInfo(info, t, lang).map((list, index) => (
            <div className="pd1" key={index}>
              <span className="bold textSecondary mr1">{list.label}:</span>
              <span>{list.value}</span>
            </div>
          ))} 
        </div>
      }
      {
        info.contingentStatus === CONTINGENT_STATUS.ENROLLED &&
        <div className="mb2">
          {
            urlImg ? 
            <div style={{ width: '220px', height: '240px' }}>
              <img 
                src={urlImg}  
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '6px',
                }}
                alt=''
              />
            </div> :
            <PhotoWrapper> <p> {t('contingentPassport_noPhoto')} </p> </PhotoWrapper>
          }
        </div>
      }
    </>
  );
}
