import { createReducer } from 'redux-starter-kit';
import { ContingentApi } from '../../_helpers/service';
import { handleError } from '../../utils/handleError';
import Dict from '../../utils/Dict';
import Notice from '../../utils/Notice';
import i18next from 'i18next';
import lodash from 'lodash'
import { NCALayerSign } from '../../components/NCALayer/NCALayerSign';
import PassportForm from '../../passport/FormData/PassportForm';
import moment from 'moment';
import paths from './../../_helpers/paths'
import { checkTypePhoto } from '../../utils/checkTypePhoto';

export const contingentPassportModule = 'contingentPassport';
const LOADING = `${contingentPassportModule}/LOADING`;
const SET_DATA = `${contingentPassportModule}/SET_DATA`;
const CLEAR_STATE = `${contingentPassportModule}/CLEAR_STATE`;
const LOADING_APPROVE = `${contingentPassportModule}/LOADING_APPROVE`;
const LOADING_REVOKE = `${contingentPassportModule}/LOADING_REVOKE`;
const LOADING_SAVE = `${contingentPassportModule}/LOADING_SAVE`;
const SET_META = `${contingentPassportModule}/SET_META`;
const LOADING_GROUPS_CHILD = `${contingentPassportModule}/LOADING_GROUPS_CHILD`;
const SET_GROUPS = `${contingentPassportModule}/SET_GROUPS`;
const LOADING_RETIREMENT = `${contingentPassportModule}/LOADING_RETIREMENT`;
const LOADING_ENROLL = `${contingentPassportModule}/LOADING_ENROLL`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingApprove: false,
  loadingRevoke: false,
  loadingGroups: false,
  loadingRetirement: false,
  loadingEnroll: false,
  loadingSave: false,
  info: {},
  data: {},
  meta: {},
  groups: [],
  contingentStatus: null,
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [SET_DATA]: (state, action) => {
    const { meta, data, info } = action.payload;
    if (meta) state.meta = meta;
    if (info) state.info = info;
    if (data) state.data = data;
  },
  [LOADING_APPROVE]: (state, action) => {
    state.loadingApprove = action.payload;
  },
  [SET_META]: (state, action) => {
    state.meta = action.payload;
  },
  [LOADING_REVOKE]: (state, action) => {
    state.loadingRevoke = action.payload;
  },
  [LOADING_GROUPS_CHILD]: (state, action) => {
    state.loadingGroups = action.payload;
  },
  [SET_GROUPS]: (state, action) => {
    state.groups = action.payload;
  }, 
  [LOADING_RETIREMENT]: (state, action) => {
    state.loadingRetirement = action.payload;
  }, 
  [LOADING_ENROLL]: (state, action) => {
    state.loadingEnroll = action.payload;
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.payload;
  }, 
  [CLEAR_STATE]: (state) => {
    return  {...initialState, invalidPassport:state.invalidPassport }
  }
});

/**
 * Actions
 */

export const loadData = id => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    if (id) {
      const { data } = await ContingentApi.getContingentItem(id);
      const { data: meta } = await ContingentApi.loadMeta(data.metadataKey);
      if (data.directionId) {
        const {data: direction } = await ContingentApi.getDirection(data.directionId);
        data['hasPrivilege'] = direction.hasPrivilege
      }
      dispatch(setData({meta, data}))
    }
  } catch (error) {
    handleError(error, i18next.t('contingentPassport_errorLoad'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const getMeta = () => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    let data = {
      metadataKey: 'form_contingent_do_child'
    }
    const { data: meta } = await ContingentApi.loadMeta(data.metadataKey);
    dispatch({ type: SET_META, payload: meta });
  } catch (error) {
    handleError(error, i18next.t('contingentPassport_errorLoadMeta'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
}

export const clearState = () => ({ type: CLEAR_STATE });

export const approveContingent = (id, confBenefit, closeModal, contingentId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_APPROVE, payload: true });
    const response = await ContingentApi.getSignString({ id, confBenefit });
    const signature = await NCALayerSign(response.data.result.signature);
    if (signature) {
      await ContingentApi.approve(id, {id, signature, confBenefit});
      Notice.success(i18next.t('contingentPassport_approveSuccess'));
      closeModal();
    }
    dispatch(loadData(contingentId))
  } catch (error) {
    handleError(error, i18next.t('contingentPassport_approveError'));
  } finally {
    dispatch({ type: LOADING_APPROVE, payload: false });
  }
};

export const retirementContingent = (id, values, closeModal, directionId, info) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_APPROVE, payload: true });
    const response = await ContingentApi.getSignatureContingent({ contingentType: "DO_CHILD", ...values, dateDropout: info.dateDropout, dropoutReason: info.dropoutReason });    
    const signature = await NCALayerSign(response.data.result.signature);
    if (signature) {
      await ContingentApi.retirement(id, { signature, ...values, dateDropout: info.dateDropout, dropoutReason: info.dropoutReason });
      dispatch(loadData(id))
      Notice.success(i18next.t('contingentPassport_retirementSuccess'));
      closeModal();
    }
  } catch (error) {
    if (error?.response?.data?.errors[0]?.code === 'error.date_dropout_is_before_enrolled_date') {
      Notice.error(i18next.t('contingentPassport_dateDropoutBeforeEnrolled'))
    } else if (error?.response?.data?.errors[0]?.code === 'error.date_dropout_is_after_current_date') {
      Notice.error(i18next.t('contingentPassport_dateDropoutAfterEnrolled'))
    } else {
      handleError(error, i18next.t('contingentPassport_retirementError'));
    }
  } finally {
    dispatch({ type: LOADING_APPROVE, payload: false });
  }
};

export const revokeContingent = (id, values, closeModal, contingentId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_REVOKE, payload: true });
    const response = await ContingentApi.getSignString({ id, ...values });
    const signature = await NCALayerSign(response.data.result.signature);
    if (signature) {
      await ContingentApi.revoke(id, { id, signature, ...values });
      dispatch(loadData(contingentId));
      Notice.success(i18next.t('contingentPassport_revokeSuccess'));
      closeModal();
    }
  } catch (error) {
    handleError(error, i18next.t('contingentPassport_revokeError'));
  } finally {
    dispatch({ type: LOADING_REVOKE, payload: false });
  }
};

export const enrollContingent = (id, values, groupId, closeModal, setIsError, directionId) => async dispatch => {
  try {
    dispatch({ type: LOADING_ENROLL, payload: true });
    const response = await ContingentApi.getSignString({ id: directionId, ...values });
    const signature = await NCALayerSign(response.data.result.signature);
    if (signature) {
      await ContingentApi.enroll(id, { childGroupId: groupId, signature, ...values });
      dispatch(loadData(id));
      Notice.success(i18next.t('contingentPassport_enrollSuccess'));
      closeModal();
    }
    setIsError(false);
  } catch (error) {
    handleError(error, i18next.t('contingentPassport_enrollError'));
  } finally {
    dispatch({ type: LOADING_ENROLL, payload: false });
  }
};

export const getGroupsChild = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_GROUPS_CHILD, payload: true });
    let { data } = await ContingentApi.getGroupsChild(id);
    if (data.status === 'SUCCESS') {
      dispatch({ type: SET_GROUPS, payload: data.result })
    }
  } catch (error) {
    handleError(error, i18next.t('contingentPassport_revokeError'));
  } finally {
    dispatch({ type: LOADING_GROUPS_CHILD, payload: false });
  }
}

export const setData = ({ meta, data }) => async (dispatch) => {
  const dictDirectionStatus = await Dict.itemsObject('gu_status_direction');
  let statusDirection = lodash.get(data, 'questionnaire.questionCodeToAnswers.status_direction.values[0].value', null);
  data['statusDirection'] = statusDirection;

  const info = {
    id: data.id,
    iin: data.iin,
    directionId: data.directionId,
    contingentStatus: data.contingentStatus,
    statusDirection: dictDirectionStatus[statusDirection] || {},
  };
  dispatch({ type: SET_DATA, payload: { meta, data, info } });
  dispatch({ type: LOADING, payload: false });
};

export const checkChangeChildPhoto = ({element, changeChildPhoto}) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true })
    if (changeChildPhoto && element.id) {
      const {data} = await ContingentApi.canChangeChildPhoto(element.id);
      if (data.status === 'SUCCESS') {
        if (data.result.canChange) {
          dispatch(savePassport({ element }))
        } else {
          Notice.error(i18next.t('contingentPassport_photoUpdatedMonth'))
        }
      }
    } else {
      dispatch(savePassport({ element }))
    }
  } catch (error) {
    handleError(error, i18next.t('contingentPassport_errorVerifyPhotos'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false })
  }
}
export const savePassport = ({element}) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true })
    await ContingentApi.savePassport({params: element})
    Notice.success(i18next.t('contingentPassport_saveFormSuccess'));
  } catch (error) {
    handleError(error, i18next.t('contingentPassport_errorSaveForm'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false })
  }
}

export const addContingent = ({ values, history, close, groupId }) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true })
    let photo = PassportForm.values['form_contingent_do_child.Foto'];
    if (checkTypePhoto(photo.file)) {
      Notice.error(i18next.t('contingentPassport_photoTypeSaveFormatNot'));
      close()
    } else {
      const response = await ContingentApi.getSignatureContingent({ contingentType: "DO_CHILD", ...values });
      const signature = await NCALayerSign(response.data.result.signature);
      if (signature) {
        let iin = PassportForm.values['form_contingent_do_child.child_iin'];
        let params = {
          signature,
          iin: iin,
          contingentType: 'DO_CHILD',
          contingentStatus: 'ENROLLED',
          isChangingGroup: true,
          serviceProviderId: null,
          childGroupId: groupId,
          enrolledDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
          contingentChanges: [...values['contingentChanges']],
        }
        await ContingentApi.addContingent(params);
        history.replace(paths.contingentEnrolledPage)
        Notice.success(i18next.t('contingentPassport_enrollSuccess'));
        close()
      }
    }
  } catch (error) {
    let iin = PassportForm.values['form_contingent_do_child.child_iin'];
    if (error?.response?.data?.errors[0]?.code === 'error.creating_already_enrolled_contingent') {
      Notice.error(i18next.t('contingentPassport_errorApplicantContingent', { iin }))
      close()
    } else if (error?.response?.data?.errors[0]?.code === 'error.creating_already_applicant_contingent') {
      Notice.error(i18next.t('contingentPassport_errorCreatingApplicantContingent'))
      close()
    } else if (error?.response?.data?.errors[0]?.code === 'error.creating_already_queued_contingent') {
      Notice.error(i18next.t('contingentPassport_errorCreatingQueuedContingent'));
    } else {
      handleError(error, i18next.t('contingentPassport_enrollError'));
    }
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false })
  }
}

export const getGroups = (info) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_GROUPS_CHILD, payload: true });
    const {data} = await ContingentApi.getGroups(info);
    if (data.status === 'SUCCESS') {
      dispatch({ type: SET_GROUPS, payload: data.result })
    }
  } catch (error) {
    handleError(error, i18next.t('contingentPassport_errorLoadGroup'));
  } finally {
    dispatch({ type: LOADING_GROUPS_CHILD, payload: false })
  }
}
